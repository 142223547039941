@import 'bootstrap/bootstrap';

/*! Leomini went crazy after this */
@import url('https://fonts.bunny.net/css2?family=Roboto:wght@100;300&display=swap');

html { scroll-behavior: smooth; }

/*-- content --*/
.vh-100 { height:100vh !important; }
.vh-90 { min-height: 92vh!important; }
.mb-6 { margin-bottom: 6rem !important; }
img.blend { mix-blend-mode: multiply; }

#rh-intro { overflow: hidden; position: relative;
    &::before {
        content: ' ';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        will-change: transform;
        z-index: -1;
        position: fixed;
        background: url(../img/mi-header.jpg) no-repeat top center;
        /* background-attachment: fixed; removed for Android */
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    h1 {font-size: 2.5rem;
        @media (min-width:768px) {font-size: 3.125rem;}
    }
    h2 {font-size: 1.25rem;
        @media (min-width:768px) {font-size: 1.875rem;}
    }
    h1, h2 {color: $white; font-weight: 100;
    }
}

.fs-5 {font-size: calc(1.25rem - .8vw)!important;
    @media (min-width:576px) {font-size: 1.25rem!important;}
}
.citat { min-height: 7rem; 
    @media (min-width:576px) {min-height: 7.5rem;}
}
.carousel-indicators { margin-bottom: -1rem; }
#scroll-indicator { padding-top: 4rem; animation: pulse 1.6s ease-in-out infinite;
	@media (min-width:768px) {padding-top: 3rem;}
}
#gallery {
    h1 {font-size: 2.5rem; color: $white; font-weight: 100;
        @media (min-width:768px) {font-size: 3.125rem;}
    }
}

.stripe { background-color: rgba(0, 0, 0, .75);
    a{
        &:hover {opacity: .5;}
    }
}
.sticky-bottom {
    position: sticky; bottom: 0px;
}

footer { background-color: rgba(255, 255, 255, .9); display: block;
    .fa-ul { margin-left: 1rem; margin-top: 1rem; padding-left: 0; }
}
.footer-list { 
    list-style: none; text-transform: uppercase;
    li { display: block;
        a { color: #2a3234; }
        a.book-btn { color:#fff; margin-top: 1rem; }
    }
}
.footer-list li:last-child { float:none; margin-top: 0; }
footer .fa-li { width: auto!important; font-size: 1rem; }
@media (min-width:768px) {
    footer .fa-ul { padding-left: 0; }
    .footer-list li { display: inline; margin-right: 1.6rem; }
    .footer-list li a.book-btn { margin-top: 0rem; }
    .footer-list li:last-child { float:right; margin-right: 0; margin-top: -.7rem; }
}
@media (max-width:920px) and (min-width:768px) {
    .footer-list li:last-child { margin-top: .5rem; }
}
.made-by { color: $gray-700;
    a { color: $gray-800; text-decoration: underline;
        &:hover { text-decoration: none; }
    }
}
.bg-fade-in { animation: clear 0.45s 0.3s forwards }
.fade-out { opacity: 0; transition: .8s ease;}

@keyframes clear {
    to { opacity: 1; transform: none; }
}

@keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.10, 1.10, 1.10);
    transform: scale3d(1.10, 1.10, 1.10);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}